import React, { Component } from "react"

export default class Service extends Component {
  constructor(props) {
    super(props)
    this.type = null
    this.url = null
    this.featuredImg = null
    this.class = null
    this.desc = null
  }

  description() {}
}
