import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import Service from "../../components/service"
// seo import
import { Seo } from "../../components/Seo"

export default class Casefile extends Service {
  constructor(props) {
    super(props)
    this.service = new Service()
    this.service.type = "CaseFile"
    this.service.url =
      "/services/" + this.service.type.toLowerCase().replace(" ", "")
    this.service.featuredImg =
      "https://i-a.cloud/cdn-cgi/imagedelivery/YZO2dKh2-nQCM3E2pZE7Kw/e5a26dbd-014a-4bd2-8333-f197efbff800/512"
    this.service.fiOrientation = "portrait"
    this.service.class = "ui-body__text-image"
    this.service.desc = this.description()
  }

  description() {
    return (
      <div>
        <p>
          Single company based investigations.{" "}
          <strong className="strong-white">
            Discover hidden opportunities
          </strong>{" "}
          by tracking <strong className="strong-white">select employees</strong>{" "}
          and <strong className="strong-white">key interactions</strong>.
        </p>
        <p>
          Use our ranking system for{" "}
          <strong className="strong-white">automatic lead qualification</strong>{" "}
          to{" "}
          <strong className="strong-white">
            streamline your sales workflow
          </strong>
          .
        </p>
      </div>
    )
  }

  render() {
    if (typeof document !== `undefined`) {
      if (window.location.pathname.includes(this.service.url)) {
        return (
          <Layout>
            <div className="ui-body-container products">
              <h1 className="body-title product-title">
                {"IA ".concat("> ").concat(this.service.type)}
              </h1>

              <div className="spacing-divider--extra-small"></div>

              <StaticImage
                src="../../assets/images/graph-stock-small.jpg"
                alt="laptop with graph on it"
                placeholder="none"
                className="products-img-full"
              />

              <div className="spacing-divider--small"></div>

              <div
                id="text-image-1"
                className="ui-body__text-image scroll-fade"
              >
                <div className="ui-body__text-image--left">
                  <h1 className="body-title">Information Is Beautiful</h1>
                  <div className="spacing-divider--small no-display-desktop">
                    <div className="no-display-desktop divider-white"></div>
                  </div>
                  <p>
                    <strong className="strong-white">IA CaseFiles</strong> are a{" "}
                    <strong className="strong-white">
                      technology-driven solution
                    </strong>{" "}
                    designed to express a{" "}
                    <strong className="strong-white">clear picture of </strong>
                    <strong className="strong-white-u">a single company</strong>
                    . Gone are the days of wasted phone calls; you provide the
                    LinkedIn URL of a target — we return an{" "}
                    <strong className="strong-white">
                      articulate and concise report
                    </strong>{" "}
                    in several relevant formats.
                  </p>
                </div>
                <div className="ui-body__text-image--right ui-body__text-image--right-text">
                  <h1 className="body-title">Remove the Guesswork</h1>
                  <div className="spacing-divider--small no-display-desktop">
                    <div className="no-display-desktop divider-white"></div>
                  </div>
                  <p>
                    All CaseFile projects begin with a consultation to{" "}
                    <strong className="strong-white">
                      build an understanding of your specific targeting
                      requirements
                    </strong>
                    . We’ll formulate the{" "}
                    <strong className="strong-white">
                      optimal search criteria
                    </strong>
                    , keyword areas and job roles to ensure{" "}
                    <strong className="strong-white">
                      relevant insights with a pinpoint degree of accuracy
                    </strong>
                    .
                  </p>
                </div>
              </div>

              <div className="spacing-divider--small"></div>

              <h1 className="body-title scroll-fade">
                We provide data-driven reports on companies, people and events
                that redefine prospecting and account-based marketing.
              </h1>

              <div className="spacing-divider--small"></div>

              <div
                id="text-image-1"
                className="ui-body__text-image scroll-fade"
              >
                <div className="ui-body__text-image--left">
                  <h1 className="body-title">Efficiency First</h1>
                  <div className="spacing-divider--small no-display-desktop">
                    <div className="no-display-desktop divider-white"></div>
                  </div>
                  <p>
                    <strong className="strong-white">
                      Time is the most valuable asset
                    </strong>
                    . IA exists to{" "}
                    <strong className="strong-white">
                      streamline your campaigns
                    </strong>{" "}
                    and our ranking system will{" "}
                    <strong className="strong-white">
                      narrow your list of contacts without any investigation on
                      your end
                    </strong>
                    .
                  </p>
                  <p>
                    <strong className="strong-white">
                      Just load up your CaseFile and go
                    </strong>
                    ! For more information on how our ranking system works
                    please visit{" "}
                    <a href="https://wiki.i-a.cloud/#ranking-system">ia.wiki</a>
                  </p>
                </div>
                <div className="ui-body__text-image--right">
                  <StaticImage
                    src="https://imagedelivery.net/YZO2dKh2-nQCM3E2pZE7Kw/f5130cd7-af33-4786-15bf-48a7fa8ba900/512"
                    alt="Example of employee data"
                    placeholder="none"
                    objectFit="contain"
                    className=""
                  />
                </div>
              </div>

              <div className="spacing-divider--small"></div>

              <div
                id="text-image-1"
                className="ui-body__text-image scroll-fade"
              >
                <div className="ui-body__text-image--left">
                  <h1 className="body-title">Vivid Depth</h1>
                  <div className="spacing-divider--small no-display-desktop">
                    <div className="no-display-desktop divider-white"></div>
                  </div>
                  <p className="full-width">
                    Our{" "}
                    <strong className="strong-white">
                      custom Sentiment Graphs offer a birds-eye view
                    </strong>{" "}
                    of the target company;{" "}
                    <strong className="strong-white">
                      a detailed mind-map
                    </strong>{" "}
                    used to present intelligence in a concise and digestible
                    manner.{" "}
                    <strong className="strong-white">
                      Track sentiment in keyword areas
                    </strong>{" "}
                    and quickly figure out who’s interested in what.
                  </p>
                  <p>
                    Need help reading graphs? Our{" "}
                    <a href="https://wiki.i-a.cloud/en/graphs">Wiki</a> has you
                    covered!
                  </p>
                </div>
                <div className="ui-body__text-image--right">
                  <StaticImage
                    src="https://imagedelivery.net/YZO2dKh2-nQCM3E2pZE7Kw/41d8489f-0457-4eec-1100-2d5d267a7800/1024"
                    alt="Example graph on the London Stock Exchange"
                    placeholder="none"
                    objectFit="contain"
                    className=""
                  />
                </div>
              </div>
            </div>
          </Layout>
        )
      }
    }
  }
}

export const Head = () => <Seo />
